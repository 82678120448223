import { Backdrop, CircularProgress, Paper } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import { actions } from '../../../../../actions';
import { denormalizeTree } from '../../../../../reducers/qbuilder';
import { useInterval } from '../../../../../services/helpers';
import EnhancedTableToolbar from '../../../../components/Table/EnhancedTableToolbar';
import { getPanelUri } from '../../../index';
import ProjectPanel from '../../index';
import { ProjectStepper } from './components/stepper/stepper';
import { ProjectDetailsForm } from './components/steps/ProjectDetailsForm';
import QuerySelectionForm from './components/steps/QuerySelectionForm';
import { RatingSelectionForm } from './components/steps/RatingSelectionForm';
import { TableCreationForm } from './components/steps/TableCreationForm';
import {
    DEFAULT_BODY_HEIGHT,
    DEFAULT_TABLE_VALUE,
    TOAST_MESSAGE_POPUP,
    URL_SAVE_INTERVAL,
    VALIDATE_BUTTONS_TIMEOUT,
    VALIDATION_BUTTONS,
    VALIDATION_MAX_RETRY,
    getEmptyRating,
} from './constant';

const stepTitles = ['Project Details', 'Table Creation', 'Query Selection', 'Rating Selection'];
const ProjectForm = (props) => {
    const { submitProject, editMode, config, errorToast, isLoading } = props;
    const theme = useTheme();
    const styles = {
        paper: {
            width: '100%',
            padding: '0 16px 8px 16px',
            marginBottom: theme.spacing(1),
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            minWidth: '600px',
            maxHeight: `calc(100vh - ${DEFAULT_BODY_HEIGHT}px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [step, setStep] = useState(0);
    const [projectName, setProjectName] = useState('');
    const [projectQuery, setProjectQuery] = useState([]);
    const [areQueryValidated, setAreQueryValidated] = useState(true);
    const validationTimeoutRef = useRef(null);
    const validationRetry = useRef(0);

    const [projectQueryId, setProjectQueryId] = useState(uuidv1());
    const [developmentQuery, setDevelopmentQuery] = useState([]);
    const [developmentQueryId, setDevelopmentQueryId] = useState(uuidv1());
    const [needUpdate, setNeedUpdate] = useState(false);
    const [tables, setTables] = useState(DEFAULT_TABLE_VALUE);
    const [configExist] = useState(searchParams.get('config') !== null);
    const [validationCheck, setValidationCheck] = useState(() => () => true);
    const isOperator = (node) => node.type !== 'FILTER';

    const convertQueryToTree = useCallback((elt, tree) => {
        if (!elt) {
            console.info('Fail to convert query to tree: Empty element:', elt, tree);
            return;
        }
        let negate = false;
        while (isOperator(elt) && elt['name'] === 'NOT') {
            negate = !negate;
            if (!elt['body']) {
                console.warn('Empty NOT operator');
                return;
            }
            elt = elt['body'];
        }
        const id = uuidv1();

        let children = elt['body'];
        if (isOperator(elt)) {
            children = elt['body'].map((child) => convertQueryToTree(child, tree));
        }

        tree.push({
            id: id,
            type: elt['type'],
            name: elt['name'],
            body: children,
            negate: negate,
            validated: true,
        });
        return id;
    }, []);

    const handleValidation = useCallback((found = false) => {
        if (validationTimeoutRef.current) {
            clearTimeout(validationTimeoutRef.current);
            validationTimeoutRef.current = null;
        }

        validationTimeoutRef.current = setTimeout(() => {
            try {
                if (validationRetry.current >= VALIDATION_MAX_RETRY) {
                    console.log('Validation process reached max retry.');
                    clearTimeout(validationTimeoutRef.current);
                    validationTimeoutRef.current = null;
                    return;
                }

                const validationButtons = document.querySelectorAll(VALIDATION_BUTTONS);
                const errorToastMessage = document.querySelector(TOAST_MESSAGE_POPUP);

                if (errorToastMessage) {
                    clearTimeout(validationTimeoutRef.current);
                    validationTimeoutRef.current = null;
                    return;
                }

                if (!validationButtons || validationButtons.length === 0) {
                    if (found) {
                        console.log('Validation process finished.');
                        clearTimeout(validationTimeoutRef.current);
                        validationTimeoutRef.current = null;
                        return;
                    }

                    console.log('Failed to find validation buttons, retrying in 1s.');
                    validationRetry.current += 1;
                    handleValidation();
                    return;
                }

                validationButtons.forEach((button) => {
                    button.click();
                });

                console.log('Queries validated.');
                validationRetry.current += 1;
                handleValidation(true);
            } catch (error) {
                console.warn('Failed to validate queries.');
                console.warn(error);
            }
        }, VALIDATE_BUTTONS_TIMEOUT);
    }, []);

    const loadConfig = useCallback(
        (configJson) => {
            if (!configJson || Object.keys(configJson).length === 0 || !configJson.tables) return;

            console.log('Loading config');

            setProjectName(configJson.projectName);

            try {
                const projectQuery = [];
                setProjectQueryId(convertQueryToTree(configJson.projectQuery, projectQuery));
                setProjectQuery(projectQuery);
            } catch (error) {
                console.warn('Failed to parse projectQuery from URL. Resetting project panel.');
                console.warn(error);
            }

            try {
                const developmentQuery = [];
                setDevelopmentQueryId(convertQueryToTree(configJson.developmentQuery, developmentQuery));
                setDevelopmentQuery(developmentQuery);
            } catch (error) {
                console.warn('Failed to parse developmentQuery from URL. Resetting project panel.');
                console.warn(error);
            }
            try {
                const parsedTables = configJson.tables.map((table) => {
                    return {
                        ...table,
                        data: table.data.map((subject) => {
                            if (subject.subcategories && subject.subcategories.length > 0) {
                                return {
                                    ...subject,
                                    subcategories: subject.subcategories.map((subcategory) => {
                                        const subcategoryQuery = [];
                                        const id = convertQueryToTree(subcategory.query, subcategoryQuery);
                                        return {
                                            ...subcategory,
                                            query: subcategoryQuery,
                                            id: id,
                                        };
                                    }),
                                };
                            } else {
                                const subjectQuery = [];
                                const id = convertQueryToTree(subject.query, subjectQuery);
                                return {
                                    ...subject,
                                    query: subjectQuery,
                                    id: id,
                                };
                            }
                        }),
                    };
                });
                setTables(parsedTables);
                validationRetry.current = 0;
                handleValidation();
            } catch (error) {
                console.warn('Failed to parse tables from URL. Resetting project panel.');
                console.warn(error);
            }
        },
        [convertQueryToTree, handleValidation],
    );

    const handleNext = useCallback(() => {
        setStep((prevStep) => prevStep + 1);
        validationRetry.current = 0;
        handleValidation();
    }, [handleValidation]);

    const handleBack = useCallback(() => {
        setStep((prevStep) => prevStep - 1);
        validationRetry.current = 0;
        handleValidation();
    }, [handleValidation]);

    useEffect(() => {
        return () => {
            if (validationTimeoutRef.current) {
                clearTimeout(validationTimeoutRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!config || !editMode) return;

        if (configExist) return;
        try {
            loadConfig(config);
        } catch (error) {
            console.warn('Failed to parse config from the model. Resetting project panel.');
            console.warn(error);
        }
    }, [config, editMode, loadConfig, configExist]);

    useEffect(() => {
        try {
            const existingConfig = searchParams.get('config');
            if (existingConfig) {
                const decodedConfig = atob(existingConfig);
                const configJson = JSON.parse(decodedConfig);
                loadConfig(configJson);
            }
        } catch (error) {
            console.warn('Failed to parse config from URL. Resetting project panel.');
            console.warn(error);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!needUpdate) setNeedUpdate(true);
    }, [projectName, projectQuery, developmentQuery, tables, setSearchParams, projectQueryId, developmentQueryId]); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        if (!needUpdate) return;

        try {
            const projectData = {
                projectName,
                projectQuery: denormalizeTree(projectQueryId, projectQuery),
                developmentQuery: denormalizeTree(developmentQueryId, developmentQuery),
                tables: denormalizeTable(tables),
            };

            const projectDataString = JSON.stringify(projectData);
            const encodedData = btoa(projectDataString);
            setSearchParams({ config: encodedData }, { replace: true });
            console.log('Saved in the url');
        } catch (error) {
            console.warn('Failed to update URL with project data');
            console.warn(error);
        }
        setNeedUpdate(false);
    }, URL_SAVE_INTERVAL);

    useEffect(() => {
        const checkButtonDisable = () => {
            const validationButtons = document.querySelectorAll(VALIDATION_BUTTONS);
            let isVisible = false;
            validationButtons.forEach((button) => {
                if (button.offsetParent !== null) {
                    isVisible = true;
                }
            });
            setAreQueryValidated(!isVisible);
        };

        checkButtonDisable();
        const observer = new MutationObserver(checkButtonDisable);

        observer.observe(document.body, { attributes: true, childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    const denormalizeTable = (tables) => {
        const modifiedTables = JSON.parse(JSON.stringify(tables));
        for (let table of modifiedTables) {
            for (let subject of table.data) {
                if (subject.subcategories && subject.subcategories.length > 0) {
                    for (let subcategory of subject.subcategories) {
                        subcategory.query = denormalizeTree(subcategory.id, subcategory.query);
                        subcategory.id = undefined;
                    }
                } else {
                    subject.query = denormalizeTree(subject.id, subject.query);
                }
                subject.id = undefined;
            }
        }

        return modifiedTables;
    };

    const handleSubmit = useCallback(() => {
        submitProject(
            projectName,
            denormalizeTree(projectQueryId, projectQuery),
            denormalizeTree(developmentQueryId, developmentQuery),
            denormalizeTable(tables),
        );
    }, [projectName, projectQuery, developmentQuery, tables, projectQueryId, developmentQueryId, submitProject]);

    const handleAdditionalRatingChange = useCallback(
        (tableIndex, subjectIndex) => {
            const updatedTables = [...tables];

            if (!updatedTables[tableIndex].data[subjectIndex].rating) {
                updatedTables[tableIndex].data[subjectIndex].rating = { ...getEmptyRating() };
            }

            const prevUpdatedRating = { ...updatedTables[tableIndex].data[subjectIndex].rating };
            const updatedRating = {
                ...getEmptyRating(updatedTables[tableIndex]?.data[subjectIndex]?.rating?.type),
                ...prevUpdatedRating,
            };

            updatedRating.additional = !updatedRating.additional;
            updatedTables[tableIndex].data[subjectIndex].rating = updatedRating;
            setTables(updatedTables);
        },
        [tables],
    );

    const handleRatingChange = useCallback(
        (tableIndex, subjectIndex, levelIndex, field, value, isAdditional) => {
            const updatedTables = [...tables];

            if (!updatedTables[tableIndex].data[subjectIndex].rating) {
                updatedTables[tableIndex].data[subjectIndex].rating = { ...getEmptyRating() };
            }

            const prevUpdatedRating = { ...updatedTables[tableIndex].data[subjectIndex].rating };
            let updatedRating = {
                ...getEmptyRating(updatedTables[tableIndex]?.data[subjectIndex]?.rating?.type),
                ...prevUpdatedRating,
            };

            if (field === 'type') {
                updatedRating = { ...getEmptyRating(value) };
                updatedRating.type = value;
            } else if (levelIndex !== null) {
                if (isAdditional) {
                    delete updatedRating.values;

                    if (field === 'devValue') {
                        const updatedDevValues = [...updatedRating.devValues];
                        updatedDevValues[levelIndex] = value;
                        updatedRating.devValues = updatedDevValues;
                    } else if (field === 'testValue') {
                        const updatedTestValues = [...updatedRating.testValues];
                        updatedTestValues[levelIndex] = value;
                        updatedRating.testValues = updatedTestValues;
                    }
                } else {
                    delete updatedRating.devValues;
                    delete updatedRating.testValues;

                    const updatedValues = [...updatedRating.values];
                    updatedValues[levelIndex] = value;
                    updatedRating.values = updatedValues;
                }
            }
            updatedTables[tableIndex].data[subjectIndex].rating = updatedRating;
            setTables(updatedTables);
        },
        [tables],
    );

    const getStepContent = useCallback(
        (step) => {
            const steps = [
                <ProjectDetailsForm
                    projectName={projectName}
                    setProjectName={setProjectName}
                    projectQuery={projectQuery}
                    setProjectQuery={setProjectQuery}
                    developmentQuery={developmentQuery}
                    setDevelopmentQuery={setDevelopmentQuery}
                    projectQueryId={projectQueryId}
                    developmentQueryId={developmentQueryId}
                    areQueryValidated={areQueryValidated}
                    handleNext={handleNext}
                    setValidationCheck={setValidationCheck}
                />,
                <TableCreationForm
                    tables={tables}
                    setTables={setTables}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setValidationCheck={setValidationCheck}
                />,
                <QuerySelectionForm
                    tables={tables}
                    setTables={setTables}
                    areQueryValidated={areQueryValidated}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setValidationCheck={setValidationCheck}
                    editMode={editMode}
                />,
                <RatingSelectionForm
                    tables={tables}
                    handleRatingChange={handleRatingChange}
                    handleSubmit={handleSubmit}
                    handleBack={handleBack}
                    editMode={editMode}
                    setValidationCheck={setValidationCheck}
                    handleAdditionalRatingChange={handleAdditionalRatingChange}
                />,
            ];
            return steps[step];
        },
        [
            projectName,
            projectQuery,
            developmentQuery,
            projectQueryId,
            developmentQueryId,
            areQueryValidated,
            tables,
            handleNext,
            handleBack,
            handleRatingChange,
            handleSubmit,
            editMode,
            handleAdditionalRatingChange,
        ],
    );

    return (
        <>
            <Paper sx={styles.paper}>
                <EnhancedTableToolbar
                    isLoading={false}
                    title={editMode ? 'Edit Project' : 'Create Project'}
                    backUri={getPanelUri(<ProjectPanel />)}
                    backText='Back to Projects'
                    margin={'no-m'}
                />
                <ProjectStepper
                    stepTitles={stepTitles}
                    step={step}
                    setStep={setStep}
                    editMode={editMode}
                    validationCheck={validationCheck}
                    errorToast={errorToast}
                    handleValidation={handleValidation}
                    validationRetry={validationRetry}
                />
                {getStepContent(step)}
            </Paper>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        errorToast: (message) => dispatch(actions.notification.add.failure(message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
